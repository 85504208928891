import { Component, OnInit } from '@angular/core';
import * as echarts from 'echarts';
import { EChartsOption, EChartsType } from 'echarts';
import BarsData from '../../data/bars.json';
import LinesData from '../../data/lines.json';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { NgIf } from '@angular/common';
import { Button } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

export interface IMLModel {
  name: string;
  roc_auc_stop_train: string;
  roc_auc_stop_test: string;
  precision: string;
  recall: string;
  f1Score: string;
}

@Component({
  selector: 'selfai-platform-model-learning-process',
  templateUrl: './model-learning-process.component.html',
  styleUrls: ['./model-learning-process.component.scss'],
  standalone: true,
  imports: [
    ProgressBarModule,
    TableModule,
    NgIf,
    Button,
    ProgressSpinnerModule,
  ],
})
export class ModelLearningProcessComponent implements OnInit {
  public barsData = BarsData;
  public linesData = LinesData;
  public progressBarValue = 0;
  public learningInProgress: boolean = false;
  public isDataPreparing: boolean = false;
  public reportUrl = 'https://appbuilder.ml-demo.ks-dev.cognive.com/applications/model-report';

  public bestModel = {
    name: 'model_1.1_params_5_3_300_7',
    roc_auc_stop_train: '0.960391687911664',
    roc_auc_stop_test: '0.915202683828315',
    precision: '0.954485563814384',
    recall: '0.93186566831976',
    f1Score: '0.93144090204786'
  }

  public finalModels: IMLModel[];

  public option = {
    grid: {
      left: 100,
      containLabel: true,
    },
    xAxis: {
      max: 1,
      from: 0,
      to: 1,
    },
    yAxis: {
      type: 'category',
      data: this.barsData.map((model) => model.model),
      inverse: true,
      animationDuration: 300,
      animationDurationUpdate: 300,
      max: 50,
    },
    series: [
      {
        name: 'train',
        type: 'bar',
        realtimeSort: true,
        data: Array(this.barsData.length).fill(0),
        label: {
          show: true,
          position: 'right',
          valueAnimation: true,
          formatter: function(d: { data: number }) {
            return +d.data.toFixed(4);
          }
        },
        color: '#19405A',
      },
      {
        name: 'test',
        type: 'bar',
        data: Array(this.barsData.length).fill(0),
        label: {
          show: true,
          position: 'right',
          valueAnimation: true,
          formatter: function(d: { data: number }) {
            return +d.data.toFixed(4);
          }
        },
        color: '#3EAED9',
      },

    ],
    legend: {
      show: true
    },
    animationDuration: 1000,
    animationDurationUpdate: 1000,
    animationEasing: 'linear',
    animationEasingUpdate: 'linear'
  };

  public barChart: EChartsType;
  public lineChartPrecision: EChartsType;
  public lineChartRecall: EChartsType;
  public lineChartF1Score: EChartsType;

  public lineChartPrecisionOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' }
    },
    xAxis: {
      type: 'category',
      data: this.linesData.map((model, index) => index),
    },
    yAxis: {
      type: 'value',
      max: 1,
      from: 0,
      to: 1,
      name: 'precision',
      nameLocation: 'center',
      nameTextStyle: {
        padding: [0, 0, 30, 0]
      }
    },
    series: [
      {
        name: 'Precision',
        type: 'line',
        smooth: true,
        stack: 'a',
        areaStyle: {
          normal: {}
        },
        data: Array(this.barsData.length).fill(0),
        color: '#3EAED9'
      },
    ],
  };

  public lineChartRecallOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' }
    },
    xAxis: {
      type: 'category',
      data: this.linesData.map((model, index) => index),
    },
    yAxis: {
      type: 'value',
      max: 1,
      from: 0,
      to: 1,
      name: 'recall',
      nameLocation: 'center',
      nameTextStyle: {
        padding: [0, 0, 30, 0]
      }
    },
    series: [
      {
        name: 'recall',
        type: 'line',
        smooth: true,
        symbol: 'none',
        stack: 'a',
        areaStyle: {
          normal: {}
        },
        data: Array(this.linesData.length).fill(0),
        color: '#93CAB5'
      },
    ],
  };

  public lineChartF1ScoreOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' }
    },
    xAxis: {
      type: 'category',
      data: this.linesData.map((model, index) => index),
    },
    yAxis: {
      type: 'value',
      max: 1,
      from: 0,
      to: 1,
      name: 'f1-score',
      nameLocation: 'center',
      nameTextStyle: {
        padding: [0, 0, 30, 0]
      }
    },
    series: [
      {
        name: 'f1-score',
        type: 'line',
        smooth: true,
        symbol: 'none',
        stack: 'a',
        areaStyle: {
          normal: {}
        },
        data: Array(this.barsData.length).fill(0),
        color: '#E3D49F'
      },
    ],
  };

  public startLearningProcess(): void {
    this.finalModels = [this.bestModel];
    this.lineChartPrecision.setOption(this.lineChartPrecisionOption);
    this.lineChartRecall.setOption(this.lineChartRecallOption);
    this.lineChartF1Score.setOption(this.lineChartF1ScoreOption);

    this.run();
    this.runLine();

    const interval = setInterval(() => {
      this.run();
      this.runLine();
    }, 2000);

    setTimeout(() => {
      this.option.series[0].data.forEach((model, index) => {
        this.option.series[0].data[index] = this.barsData[index].roc_auc_stop_train;
      });
      this.option.series[1].data.forEach((model, index) => {
        this.option.series[1].data[index] = this.barsData[index].roc_auc_stop_test;
      });

      this.lineChartPrecisionOption.series[0].data.forEach((model, index) => {
        this.lineChartPrecisionOption.series[0].data[index] = this.linesData[index].precision;
      });
      this.lineChartRecallOption.series[0].data.forEach((model, index) => {
        this.lineChartRecallOption.series[0].data[index] = this.linesData[index].recall;
      });
      this.lineChartF1ScoreOption.series[0].data.forEach((model, index) => {
        this.lineChartF1ScoreOption.series[0].data[index] = this.linesData[index]['f1-score'];
      });

      this.barChart.setOption(this.option as EChartsOption);

      this.lineChartPrecision.setOption(this.lineChartPrecisionOption);
      this.lineChartRecall.setOption(this.lineChartRecallOption);
      this.lineChartF1Score.setOption(this.lineChartF1ScoreOption);

      clearInterval(interval);
      this.progressBarValue = 100;
      this.finalModels = [this.bestModel];
    }, 60000);

    setTimeout(() => {
      this.learningInProgress = false;
    }, 62000);
  }

  public ngOnInit(): void {
    this.isDataPreparing = true;
    this.learningInProgress = true;

    this.barChart = echarts.init(document.getElementById('model-learning-process'));
    this.lineChartPrecision = echarts.init(document.getElementById('model-learning-process-line-precision'));
    this.lineChartRecall = echarts.init(document.getElementById('model-learning-process-line-recall'));
    this.lineChartF1Score = echarts.init(document.getElementById('model-learning-process-line-f1-score'));

    setTimeout(() => {
      this.isDataPreparing = false;
      this.startLearningProcess();
    }, 5000);
  }

  private randomIntFromInterval(min: number, max: number): number { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public run(): void {
    const trainData = this.option.series[0].data;
    const testData = this.option.series[1].data;

    trainData.forEach((model, index) => {
      if (trainData[index] >= this.barsData[index].roc_auc_stop_train) {
        trainData[index] = this.barsData[index].roc_auc_stop_train
        return;
      }
      const step = this.barsData[index].roc_auc_stop_train / this.randomIntFromInterval(1, 60);
      if (trainData[index] + step < this.barsData[index].roc_auc_stop_train) {
        trainData[index] += step;
      } else if (this.barsData[index].roc_auc_stop_train < trainData[index]) {
        trainData[index] += (this.barsData[index].roc_auc_stop_train - trainData[index]) * 0.1
      }
    });

    testData.forEach((model, index) => {
      if (testData[index] >= this.barsData[index].roc_auc_stop_test) {
        testData[index] = this.barsData[index].roc_auc_stop_test
        return;
      }
      const step = this.barsData[index].roc_auc_stop_test / this.randomIntFromInterval(8, 60);
      if (testData[index] + step < this.barsData[index].roc_auc_stop_test) {
        testData[index] += step;
      } else if (this.barsData[index].roc_auc_stop_test < testData[index]) {
        testData[index] += (this.barsData[index].roc_auc_stop_test - testData[index]) * 0.1
      }
    });

    this.barChart.setOption(this.option as EChartsOption);
    this.progressBarValue = +(this.progressBarValue + 100/30).toFixed(2);
  }

  public runLine(): void {
    const precisionData = this.lineChartPrecisionOption.series[0].data;
    const recallData = this.lineChartRecallOption.series[0].data;
    const f1ScoreData = this.lineChartF1ScoreOption.series[0].data;

    precisionData.forEach((model, index) => {
      if (precisionData[index] >= this.linesData[index].precision) {
        precisionData[index] = this.linesData[index].precision;
        return;
      }
      const step = this.linesData[index].precision / this.randomIntFromInterval(1, 60);
      if (precisionData[index] + step < this.linesData[index].precision) {
        precisionData[index] += step;
      } else if (this.linesData[index].precision < precisionData[index]) {
        precisionData[index] += (this.linesData[index].precision - precisionData[index]) * 0.1;
      }
    });
    recallData.forEach((model, index) => {
      if (recallData[index] >= this.linesData[index].recall) {
        recallData[index] = this.linesData[index].recall;
        return;
      }
      const step = this.linesData[index].recall / this.randomIntFromInterval(1, 60);
      if (recallData[index] + step < this.linesData[index].recall) {
        recallData[index] += step;
      } else if (this.linesData[index].recall < recallData[index]) {
        recallData[index] += (this.linesData[index].recall - recallData[index]) * 0.1;
      }
    });
    f1ScoreData.forEach((model, index) => {
      if (f1ScoreData[index] >= this.linesData[index]['f1-score']) {
        f1ScoreData[index] = this.linesData[index]['f1-score'];
        return;
      }
      const step = this.linesData[index]['f1-score'] / this.randomIntFromInterval(1, 60);
      if (f1ScoreData[index] + step < this.linesData[index]['f1-score']) {
        f1ScoreData[index] += step;
      } else if (this.linesData[index]['f1-score'] < f1ScoreData[index]) {
        f1ScoreData[index] += (this.linesData[index]['f1-score'] - f1ScoreData[index]) * 0.1;
      }
    });

    this.lineChartPrecision.setOption(this.lineChartPrecisionOption);
    this.lineChartRecall.setOption(this.lineChartRecallOption);
    this.lineChartF1Score.setOption(this.lineChartF1ScoreOption);
  }

  public navigateToReport(): void {
    window.open(this.reportUrl, '_blank');
  }
}
