import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { Button } from 'primeng/button';
import { DialogService } from '@selfai-platform/shell';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'selfai-platform-generated-model-info',
  templateUrl: './generated-model-info.component.html',
  styleUrls: ['./generated-model-info.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PanelModule,
    Button,
  ],
})
export class GeneratedModelInfoComponent implements OnInit {
  public generatedWorkflowId = '75a71f7f-90a3-4d6c-87fb-8c1b597515bb';
  public generatedWorkflowUrl = `https://pipeline.ml-demo.ks-dev.cognive.com/pipeline/workflow/${this.generatedWorkflowId}`;

  constructor(private readonly dialogService: DialogService, @Inject(DOCUMENT) private document: Document) {
  }

  public navigateToGeneratedWorkflow(): void {
    this.document.defaultView.open(this.generatedWorkflowUrl, '_blank');
  }

  public ngOnInit(): void {
    if (this.dialogService.data) {
      this.generatedWorkflowUrl = this.dialogService.data['data'];
      this.generatedWorkflowId = (this.dialogService.data['data'] as string).split('/').pop();
    }
  }
}
