import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DiscoveryReportApiService } from './discovery-report-api.service';

@Injectable({ providedIn: 'root' })
export class DiscoveryReportDomainService {
  constructor(private readonly discoveryReportApiService: DiscoveryReportApiService) {}

  getReport(id: string): Observable<string> {
    return this.discoveryReportApiService.getReport(id);
  }

  getParametrizationUrl(): string {
    return this.discoveryReportApiService.aiProfileSegmentationUrl;
  }
}
