<div *ngIf="isDataPreparing | async" class="preparing-data flex flex-column align-items-center justify-content-center">
  <p-progressSpinner ariaLabel="loading" />
  <div class="text-3lg">Preparing data</div>
</div>

<p-table *ngIf="!learningInProgress" [value]="finalModels" [tableStyle]="{ 'min-width': '50rem' }">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      Final result
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>model name</th>
      <th>max_score</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-model>
    <tr>
      <td>{{ model.model_id }}</td>
      <td>{{ model.max_score }}</td>
      <td><p-button label="Go to model report" (click)="navigateToReport()"></p-button></td>
    </tr>
  </ng-template>
</p-table>

<div style="width: 90vw; height: 80vh" class="grid">
  <div style="height: 100%" id="model-learning" class="col-12"></div>
</div>
