import { ModelLearningResult } from '../services';

export const REPORT_URL_1 = 'https://appbuilder.ml-dev.ks-dev.cognive.com/applications/0845d616-364e-4841-b061-0e241d2e316d';
export const REPORT_URL_2 = 'https://appbuilder.ml-dev.ks-dev.cognive.com/applications/1740755528643_COPY_OF_0845d616-364e-4841-b061-0e241d2e316d';
export const REPORT_URL_3 = 'https://appbuilder.ml-dev.ks-dev.cognive.com/applications/0845d616-364e-4841-b061-0e241d2rrrr';

export const LEARNING_RACE_BAR_OPTION = {
  grid: {
    left: 100,
    containLabel: true,
  },
  xAxis: {
    max: 1,
    from: 0,
    to: 1,
  },
  yAxis: {
    type: 'category',
    data: [] as string[],
    inverse: true,
    animationDuration: 300,
    animationDurationUpdate: 300,
    max: 20,
  },
  series: [
    {
      name: 'train',
      type: 'bar',
      realtimeSort: true,
      data: [] as number[],
      label: {
        show: true,
        position: 'right',
        valueAnimation: true,
        animation: true,
        animationDuration: 6000,
        animationDurationUpdate: 6000,
      },
      color: '#19405A',
    },
  ],
  legend: {
    show: true
  },
  animationDuration: 5000,
  animationDurationUpdate: 5000,
  animationEasing: 'linear',
  animationEasingUpdate: 'linear'
};
