import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@selfai-platform/shared';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DiscoveryReportApiService {
  public readonly aiProfileSegmentationUrl = 'https://appbuilder.ml-dev.ks-dev.cognive.com/applications/Parametrization';
  get apiUrl(): string {
    const apiUrl = this.configService.getConfig().discovery.apiUrl;

    if (!apiUrl) {
      throw new Error('Discovery API URL is not defined. Please check the configuration file.');
    }

    return apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl;
  }

  constructor(
    private readonly configService: ConfigService,
    private readonly http: HttpClient,
  ) {}

  getReport(id: string): Observable<string> {
    const username = this.configService.getConfig().discovery.username;
    const password = this.configService.getConfig().discovery.password;
    const headers = new Headers();

    headers.append('Content-Type', 'text/html');
    headers.append('Accept', 'text/html');
    headers.append('Authorization', `Basic ${btoa(`${username}:${password}`)}`);

    return from(fetch(`${this.apiUrl}/api/v1/get_html?file_id=${id}`, { headers }).then((response) => response.text()));
  }
}
