import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AngularHookNames,
  CreateDataFrameRawParameters,
  CubeComment,
  CubeWorkflowData,
  EnrichmentToolBatchRawParameters,
  EnrichmentToolRawParameters,
  ExternalSqlQueryRawParameters,
  GraphNodeOptionSerialized,
  GraphNodeOptions,
  IAngularJsPipelineOptions,
  InferredWorkflowStateError,
  KeRefButtonEditResult,
  KeRefButttonEditorOptions,
  PipelineVariablesEditResult,
  PipelineVariablesEditorOptions,
  ReadDataFrameRawParameters,
  VersionManagerActions,
  VersionsManagerRunResult,
  VersionsManagerWorkflowData,
  WorkflowExpressionData,
  WorkflowJsonToColumnTransformationData,
  WorkflowTriggerData,
  WriteDataFrameRawParameters,
  WriteDataFrameTransitRawParameters,
  GraphNode,
  WorkflowRunnerLegacyData,
} from '@selfai-platform/pipeline-common';
import { AlertOptions, AlertService, AlertSeverity, DestroyService } from '@selfai-platform/shared';
import {
  AiChatService,
  BreadcrumbsMenuItem,
  BreadcrumbsMenuService,
  DialogService,
  ErrorService,
  KE_ROOT_ROUTE,
  KE_WORKFLOW_PATH,
  provideDialogService,
} from '@selfai-platform/shell';
import { WorkflowStateService } from '@selfai-platform/storage';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Observable, Subscription, take, takeUntil, map } from 'rxjs';
import { AngularJsBridgeService, AngularJsLoaderService } from '../../angularjs-services';
import { CubeDialogStateService } from '../../cubes/services/cube-dialog-state.service';
import { CubeDialogService } from '../../cubes/services/cube-dialog.service';
import { KeRefButtonsService } from '../../ref-buttons-editor';
import { PipelineVariablesService } from '../../variables-editor';
import { VersionsManagerService } from '../../versions-manager';
import { WorkflowSettingsService } from '../../workflow-settings';
import { WorkflowEditorElementService, WorkflowEditorFacadeService } from '../services';
import { CubeDialogManagementService } from '../../cubes/services/cube-dialog-management.service';
import { DialogHeaderService } from '../../cubes/components/dialog-header/dialog-header.service';

@Component({
    selector: 'selfai-platform-workflow-editor',
    templateUrl: './workflow-editor.component.html',
    styleUrls: ['./workflow-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DestroyService,
        ConfirmationService,
        ...provideDialogService(CubeDialogService, CubeDialogStateService, CubeDialogManagementService, VersionsManagerService, PipelineVariablesService, WorkflowSettingsService, KeRefButtonsService, ErrorService, DialogService, DialogHeaderService),
    ],
    standalone: false
})
export class WorkflowEditorComponent implements OnInit, OnDestroy {
  workflowId: string | null = null;
  app: ng.auto.IInjectorService | undefined;
  $currentErrorWindow: Subscription | undefined;
  showPipeline = true;
  $versionDialog: Subscription | undefined;
  chatVisible$: BehaviorSubject<boolean>;

  public cubeDialogs$: Observable<any>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly angularJsLoaderService: AngularJsLoaderService,
    private readonly router: Router,
    private readonly variablesService: PipelineVariablesService,
    private readonly workflowSettingsService: WorkflowSettingsService,
    private readonly cubeDialogService: CubeDialogService,
    private readonly versionsManagerService: VersionsManagerService,
    private readonly errorService: ErrorService,
    private readonly destroy$: DestroyService,
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
    private readonly workflowStateService: WorkflowStateService,
    private readonly elementRef: ElementRef,
    private readonly alertService: AlertService,
    private readonly workflowEditorElementService: WorkflowEditorElementService,
    private readonly keRefButtonsService: KeRefButtonsService,
    private readonly angularBridgeService: AngularJsBridgeService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly translate: TranslateService,
    private readonly aiChatService: AiChatService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
  ) {}

  ngOnInit(): void {
    this.initPipeline();
    this.setBreadcrumbs();
    this.chatVisible$ = this.aiChatService.chatVisible$;
    this.cubeDialogs$ = this.cubeDialogManagementService.collapsedDialogs$
      .pipe(
        map((dialog) => dialog.map((data) => {
          const selectedNode = data.selectedNode as GraphNode & { id: string, name: string, uiName: string };
          return {
            title: selectedNode.name,
            id: selectedNode.id,
            uiName: selectedNode?.uiName,
          }
        }))
      );
  }

  public closeDialog(nodeId: string): void {
    this.cubeDialogManagementService.closeCollapsed(nodeId);
  }

  public expandDialog(nodeId: string): void {
    this.cubeDialogManagementService.expand(nodeId);
  }

  reloadPAge(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  ngOnDestroy(): void {
    this.angularJsLoaderService.destroyPipeline();
    this.closeError();
    this.workflowStateService.clearWorkflowState();
  }

  closeError(): void {
    this.$currentErrorWindow?.unsubscribe();
  }

  closeVersionManagerDialog(): void {
    this.$versionDialog?.unsubscribe();
    this.versionsManagerService.closeDialog();
  }

  onDropCube(
    event: CdkDragDrop<GraphNodeOptionSerialized[], GraphNodeOptionSerialized[], Observable<GraphNodeOptionSerialized>>,
  ): void {
    if (event.item.data) {
      event.item.data.pipe(take(1)).subscribe((data: GraphNodeOptionSerialized) => {
        this.workflowEditorFacadeService.addNode(data, event.dropPoint);
      });
    }
  }

  private initPipeline(): void {
    this.route.paramMap.subscribe((params) => {
      this.workflowId = params.get('id') as string;
      const options: IAngularJsPipelineOptions = {
        workflowId: this.workflowId,
      };
      this.angularJsLoaderService.bootstrapPipeline(options, () => {
        this.setHooks();
        this.workflowEditorElementService.initService(this.elementRef.nativeElement);
      });
    });
  }

  private setBreadcrumbs(): void {
    this.workflowStateService.pipe(takeUntil(this.destroy$)).subscribe((flow) => {
      const breadcrumbItems: BreadcrumbsMenuItem[] = [
        {
          label: this.translate.instant('shell.dp.ui.flows'),
          routerLink: ['/', KE_ROOT_ROUTE],
        },
      ];
      if (flow) {
        breadcrumbItems.push({
          label: flow.thirdPartyData?.gui?.name || '',
          routerLink: ['/', KE_ROOT_ROUTE, KE_WORKFLOW_PATH, flow.id],
        });
      }
      this.breadcrumbsMenuService.setBreadcrumbsMenu(breadcrumbItems);
    });
  }

  private setHooks(): void {
    // TODO: Refactor this to use multiple dependency injection
    this.registerNavigateToHook();
    this.registerGoToWorkflowHook();
    this.registerShowErrorWindowHook();
    this.registerShowVariablesHook();
    this.registerShowSettingsHook();
    this.registerCubeCreateTriggerHook();
    this.registerCubeShowJsonToColumnTransformationHook();
    this.registerReadDataFrameHook();
    this.registerWriteDataFrameTransitHook();
    this.registerWriteDataFrameHook();
    this.registerCreateDataFrameHook();
    this.registerExternalSqlQuery();
    this.registerEnrichmentTool();
    this.registerEnrichmentToolBatch();
    this.registerCubeExpressionToolHook();
    this.registerCubeCommentHook();
    this.registerCubeAddToFavoriteHook();
    this.registerCubeCodeEditorTransformation();
    this.registerCmsFormCreator();
    this.registerVersionManagerHook();
    this.registerAlertMessageHook();
    this.registerButtonManagerHook();
    this.registerWorkflowRunner();
    this.registerShowMLModelLearning();
    this.registerMlModelGenerationComplete();
    this.registerModelCubeGenerationComplete();
    this.registerShowModelBarRaceChart();
  }

  private registerVersionManagerHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.ShowVersions, (data) => {
      this.$versionDialog = this.versionsManagerService
        .showEditor(data as VersionsManagerWorkflowData)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (dataDialog?: VersionsManagerRunResult) => {
            if (dataDialog?.action === VersionManagerActions.SaveAndOpenWorkflow && dataDialog.workflow) {
              this.workflowEditorFacadeService.saveAndOpenWorkflow(dataDialog.workflow);
            } else {
              this.workflowEditorFacadeService.reOpenWorkflow();
            }
          },
        });
    });
  }

  private registerButtonManagerHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.ShowButtonEditor, (data) => {
      this.keRefButtonsService
        .showEditor(data as KeRefButttonEditorOptions)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (dataDialog?: KeRefButtonEditResult) => {
            if (dataDialog?.needSave) {
              this.workflowEditorFacadeService.saveRefrenceButtons(dataDialog.items);
            }
          },
        });
    });
  }

  private registerCubeCommentHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeComment, (data) => {
      this.cubeDialogService
        .showComment(data as CubeComment)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerCubeAddToFavoriteHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.AddToFavorite, (data) => {
      const nodeOptions = data as GraphNodeOptions;
      this.cubeDialogService.showFavoriteAddForm(nodeOptions);
    });
  }

  private registerCubeShowJsonToColumnTransformationHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeShowJsonToColumnTransformation, (data) => {
      this.cubeDialogService
        .showJsonToColumnTransformation(data as CubeWorkflowData<WorkflowJsonToColumnTransformationData>)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerCubeExpressionToolHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeExpressionTool, (data) => {
      this.cubeDialogService
        .showExpressionTool(data as CubeWorkflowData<WorkflowExpressionData>)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerReadDataFrameHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeReadDataFrame, (data) => {
      this.cubeDialogService
        .showReadDataFrame(data as CubeWorkflowData<ReadDataFrameRawParameters>)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerWriteDataFrameTransitHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeWriteDataFrameTransit, (data) => {
      this.cubeDialogService
        .showWriteDataFrameTransit(data as CubeWorkflowData<WriteDataFrameTransitRawParameters>)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerWriteDataFrameHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeWriteDataFrame, (data) => {
      this.cubeDialogService
        .showWriteDataFrame(data as CubeWorkflowData<WriteDataFrameRawParameters>)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerCreateDataFrameHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeCreateDataFrame, (data) => {
      this.cubeDialogService
        .showCreateDataFrame(data as CubeWorkflowData<CreateDataFrameRawParameters>)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerExternalSqlQuery(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeExternalSqlQuery, (data) => {
      this.cubeDialogService
        .showExternalSqlQuery(data as CubeWorkflowData<ExternalSqlQueryRawParameters>)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerEnrichmentTool(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeEnrichmentTool, (data) => {
      this.cubeDialogService
        .showEnrichmentTool(data as CubeWorkflowData<EnrichmentToolRawParameters>)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerEnrichmentToolBatch(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeEnrichmentToolBatch, (data) => {
      this.cubeDialogService
        .showEnrichmentToolBatch(data as CubeWorkflowData<EnrichmentToolBatchRawParameters>)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerCubeCreateTriggerHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeCreateTrigger, (data) => {
      this.cubeDialogService
        .showCreateTrigger(data as CubeWorkflowData<WorkflowTriggerData>)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerCubeCodeEditorTransformation(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeCodeEditorTransformation, (data) => {
      this.cubeDialogService
        .showCodeEditorTransformation(data as CubeWorkflowData)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerCmsFormCreator(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeCmsFormCreator, (data) => {
      this.cubeDialogService
        .showCmsFormCreator(data as CubeWorkflowData)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerWorkflowRunner(): void {
    this.angularBridgeService.registerHook(AngularHookNames.CubeWorkflowRunner, (data) => {
      this.cubeDialogService
        .showWorkflowRunner(data as CubeWorkflowData<WorkflowRunnerLegacyData>)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerShowVariablesHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.ShowVariables, (data) => {
      this.variablesService
        .showEditor(data as PipelineVariablesEditorOptions)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (dataDialog?: PipelineVariablesEditResult) => {
            if (dataDialog?.needSave) {
              this.workflowEditorFacadeService.saveWorkflowVariables(dataDialog.items);
            }
          },
        });
    });
  }

  private registerShowSettingsHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.ShowSettings, (data) => {
      this.workflowSettingsService.showEditor(data).pipe(takeUntil(this.destroy$));
    });
  }

  private registerShowMLModelLearning(): void {
    this.angularBridgeService.registerHook(AngularHookNames.MLStartLearning, (data) => {
      this.cubeDialogService.showModelLearningProcess(data);
    })
  }

  private registerShowModelBarRaceChart(): void {
    this.angularBridgeService.registerHook(AngularHookNames.MLStartPollingModels, (data) => {
      this.cubeDialogService.showModelBarRaceChart(data);
    })
  }

  private registerMlModelGenerationComplete(): void {
    this.angularBridgeService.registerHook(AngularHookNames.MlModelGenerationComplete, (data) => {
      this.cubeDialogService.showGeneratedModelInfo(data);
    })
  }

  private registerModelCubeGenerationComplete(): void {
    this.angularBridgeService.registerHook(AngularHookNames.ModelCubeGenerationComplete, (data) => {
      this.cubeDialogService.showModelCubeGeneratedModelInfo(data);
    })
  }

  private registerAlertMessageHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.AlertMessage, (data) => {
      const { message, severity, options } = data as {
        message: string;
        severity: AlertSeverity;
        options?: AlertOptions;
      };

      this.alertService.message(message, severity, options);
    });
  }

  private registerShowErrorWindowHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.ShowErrorWindow, (error) => {
      const err = error as InferredWorkflowStateError;
      this.$currentErrorWindow = this.errorService
        .showError(`${err.title}`, err)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    });
  }

  private registerNavigateToHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.NavigateTo, (data) => {
      const { url, data: errorData } = data as { url: string; data: Record<string, unknown> };
      this.router.navigateByUrl(url as string, { state: errorData });
    });
  }

  private registerGoToWorkflowHook(): void {
    this.angularBridgeService.registerHook(AngularHookNames.GoToWorkflow, (workflowId) => {
      // Dirty hack for correct workflow navigate
      this.closeVersionManagerDialog();
      this.router.navigate(['/', KE_ROOT_ROUTE], { skipLocationChange: true }).then(() => {
        this.router.navigate(['/', KE_ROOT_ROUTE, KE_WORKFLOW_PATH, workflowId as string]);
      });
    });
  }
}
