<div *ngIf="isDataPreparing" class="preparing-data flex flex-column align-items-center justify-content-center">
  <p-progressSpinner ariaLabel="loading" />
  <div class="text-3lg">Preparing data</div>
</div>

<p-progressBar *ngIf="learningInProgress && !isDataPreparing" [value]="progressBarValue" />

<p-table *ngIf="!learningInProgress" [value]="finalModels" [tableStyle]="{ 'min-width': '50rem' }">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      Final result
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>model name</th>
      <th>roc_auc_train</th>
      <th>roc_auc_test</th>
      <th>precision</th>
      <th>recall</th>
      <th>f1-score</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-model>
    <tr>
      <td>{{ model.name }}</td>
      <td>{{ model.roc_auc_stop_train }}</td>
      <td>{{ model.roc_auc_stop_test }}</td>
      <td>{{ model.precision }}</td>
      <td>{{ model.recall }}</td>
      <td>{{ model.f1Score }}</td>
      <td><p-button label="Go to model report" (click)="navigateToReport()"></p-button></td>
    </tr>
  </ng-template>
</p-table>

<div style="width: 90vw; height: 80vh" class="grid">
  <div style="height: 100%" id="model-learning-process" class="col-8"></div>
  <div style="height: 100%" class="flex flex-column col-4">
    <div style="height: 25vh" id="model-learning-process-line-precision"></div>
    <div style="height: 25vh" id="model-learning-process-line-recall"></div>
    <div style="height: 25vh" id="model-learning-process-line-f1-score"></div>
  </div>
</div>
