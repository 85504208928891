import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';

import {
  AiBotApiChatCompletionsRequestDTO,
  AiBotApiChatRequestDTO,
  AiBotApiChatResponseDTO,
  AiBotApiChatUrlsResponseDTO,
  AiBotApiTagsResponseDTO,
  AiBotApiVersionResponseDTO,
} from '../models';
import { AI_BOT_API_ROUTES, OLLAMA_API_PREFIX } from '../constants';
import {
  ConfigService,
  KdConfigService,
  SelfaiAuthService,
} from '@selfai-platform/shared';

@Injectable()
export class AiChatApiService {
  private rootApiUrl: string;
  constructor(
    private readonly http: HttpClient,
    private readonly kdConfigService: KdConfigService,
    private readonly configService: ConfigService,
    private readonly selfaiAuthService: SelfaiAuthService,
  ) {
    this.configService.configLoaded$.subscribe((isLoaded) => {
      if (isLoaded) {
        this.rootApiUrl = this.kdConfigService.getConfig().apiUrl + OLLAMA_API_PREFIX;
      }
    })
  }

  public getAiVersion(): Observable<AiBotApiVersionResponseDTO> {
    return this.http.get<AiBotApiVersionResponseDTO>(this.rootApiUrl + AI_BOT_API_ROUTES.VERSION);
  }

  public getAiTags(): Observable<AiBotApiTagsResponseDTO> {
    return this.http.get<AiBotApiTagsResponseDTO>(this.rootApiUrl + AI_BOT_API_ROUTES.TAGS);
  }

  public getAiUrls(): Observable<AiBotApiChatUrlsResponseDTO> {
    return this.http.get<AiBotApiChatUrlsResponseDTO>(this.rootApiUrl + AI_BOT_API_ROUTES.URLS);
  }

  public saveChat(chatRequestDTO: AiBotApiChatRequestDTO): Observable<AiBotApiChatResponseDTO[]> {
    return this.sendChatRequest<AiBotApiChatRequestDTO>(AI_BOT_API_ROUTES.CHAT, chatRequestDTO);
  }

  public saveChatCompletions(
    chatCompletionsRequestDTO: AiBotApiChatCompletionsRequestDTO
  ): Observable<AiBotApiChatResponseDTO[]> {
    return this.sendChatRequest<AiBotApiChatCompletionsRequestDTO>(
      AI_BOT_API_ROUTES.CHAT_COMPLETIONS,
      chatCompletionsRequestDTO
    );
  }

  private sendChatRequest<T>(url: string, data: T): Observable<AiBotApiChatResponseDTO[]> {
    return from(this.selfaiAuthService.getProvider().updateToken()).pipe(switchMap(() =>
      from(this.selfaiAuthService.getProvider().getToken()).pipe(
        switchMap((token) => from(fetch(this.rootApiUrl + url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + token
          },
          body: JSON.stringify(data),
        })).pipe(
          switchMap((response) => from(response.text())),
          map((text) => this.parse(text) as AiBotApiChatResponseDTO[]),
          catchError(error => of('dataFetchError', error)),
        )
    ))));
  }

  private parse(data: string): any {
    if (!data || data.length < 1) {
      return null;
    }
    const rows = data.split(/\n|\n\r/).filter(Boolean);
    const parsedRows = rows.map((row) => JSON.parse(row));
    return parsedRows.filter(((parsedRow) => parsedRow?.message?.content));
  };
}
