import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ModelLearningResult {
  run_id: string;
  model_id: string;
  min_score: number;
  max_score: number;
  log_time: Date;
}

export interface ModelLearningApiData {
  data: ModelLearningResult[];
  done: boolean;
}

@Injectable()
export class ModelLearningService {
  private readonly apiUrl = 'https://discovery.kfk-002.cognive.com';
  constructor(private readonly httpClient: HttpClient) {}

  public getModelLearningDataByRunId(runId: string): Observable<ModelLearningApiData> {
    return this.httpClient.get<ModelLearningApiData>(`${this.apiUrl}/api/v1/get_models_logs?run_id=${runId}`);
  }
}
