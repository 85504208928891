import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService, PipelinePermissionEntity, WorkflowPermissionAction } from '@selfai-platform/shared';
import { combineLatest, map, Observable } from 'rxjs';
import { BreadcrumbsMenuItem, BreadcrumbsMenuService } from '../../breadcrumbs-menu';
import { SHELL_CONFIG, ShellConfig } from '../../shell-config';
import { getStartPageWidgetsConfigs } from './home-page-base-widget/deafult-widgets';
import { HomePageWidgetConfig } from './home-page-base-widget/home-page-base-widget-config';
import { HomePageWidgets } from './home-page-widgets';

@Component({
  selector: 'selfai-platform-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HomePageComponent implements OnInit {
  widgetConfigs$: Observable<HomePageWidgetConfig[]>;

  private readonly breadcrumbItems: BreadcrumbsMenuItem[] = [
    {
      label: this.translate.instant('shell.menu.multiverse'),
      routerLink: ['/'],
    },
  ];

  constructor(
    @Inject(SHELL_CONFIG) private readonly shellConfig: ShellConfig,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly permissionService: PermissionService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
    this.widgetConfigs$ = combineLatest({
      [HomePageWidgets.Pipeline]: this.permissionService.checkPermission({
        entity: PipelinePermissionEntity.Workflows,
        action: WorkflowPermissionAction.List,
      }),
    }).pipe(
      map((permissions) => {
        const widgetNames = this.shellConfig.startPage.widgets.filter((widget) =>
          this.checkPermission(widget, permissions),
        );

        return getStartPageWidgetsConfigs([
          ...widgetNames,
          HomePageWidgets.InterpretableModels,
          HomePageWidgets.QuickPrototypes,
          HomePageWidgets.HighPerformanceModels,
          HomePageWidgets.LLMAgents
        ]);
      }),
    );
  }

  checkPermission(widget: HomePageWidgets, permissions: Partial<Record<HomePageWidgets, boolean>>): boolean {
    // if we didn't check permissions for this widget, we assume that it's allowed
    return Object.prototype.hasOwnProperty.call(permissions, widget) ? permissions[widget] : true;
  }
}
