import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModelLearningApiData, ModelLearningResult, ModelLearningService } from '../../services';
import { DialogService } from '@selfai-platform/shell';
import { Button } from 'primeng/button';
import { AsyncPipe, NgIf } from '@angular/common';
import { PrimeTemplate } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { EChartsOption, EChartsType } from 'echarts';
import * as echarts from 'echarts';
import {
  LEARNING_RACE_BAR_OPTION,
  REPORT_URL_1,
  REPORT_URL_2,
  REPORT_URL_3,
} from '../../constants/model-learning-process.constants';
import { BehaviorSubject, takeUntil, tap } from 'rxjs';
import { validate as isValidUUID } from 'uuid';
import { DestroyService } from '@selfai-platform/shared';

@Component({
  selector: 'selfai-platform-model-learning-race-bar',
  templateUrl: './model-learning-race-bar.component.html',
  styleUrls: ['./model-learning-race-bar.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ModelLearningService,
  ],
  imports: [
    Button,
    NgIf,
    PrimeTemplate,
    ProgressBarModule,
    ProgressSpinnerModule,
    TableModule,
    AsyncPipe,
  ],
})
export class ModelLearningRaceBarComponent implements OnInit {
  public option = LEARNING_RACE_BAR_OPTION;
  public isDataPreparing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public learningInProgress: boolean = false;
  public finalModels: [ModelLearningResult];
  public barChart: EChartsType;

  private intervals: number[] = [];
  private reportUrls = {
    reportUrl1: REPORT_URL_1,
    reportUrl2: REPORT_URL_2,
    reportUrl3: REPORT_URL_3,
  }

  constructor(
    private readonly modelLearningService: ModelLearningService,
    private readonly dialogService: DialogService,
    private readonly destroy$: DestroyService,
  ) {}

  public ngOnInit(): void {
    this.barChart = echarts.init(document.getElementById('model-learning'));
    const runId = (this.dialogService.data as { data: string, mlCube: string }).data;
    if (runId && isValidUUID(runId)) {
      this.setData(runId);
      const interval = setInterval(() => {
        this.setData(runId);
      }, 5000);
      this.intervals = [...this.intervals, interval]
    }
    this.learningInProgress = true;
  }

  public setData(id: string): void {
    const sub = this.modelLearningService.getModelLearningDataByRunId(id)
      .pipe(tap(({ data }) => !!data.length && this.isDataPreparing.next(false)), takeUntil(this.destroy$))
      .subscribe(({ data, done }) => {
        this.option.yAxis.data = data.map(d => d.model_id);
        this.option.series[0].data = data.map(d => d.max_score);
        this.barChart.setOption(this.option as EChartsOption);

        if (done) {
          this.finalModels = [this.getBestModel(data)];
          this.learningInProgress = false;
          this.intervals.map((interval) => clearInterval(interval));
        }
        sub.unsubscribe();
      });
  }

  public getBestModel(result: ModelLearningResult[]): ModelLearningResult {
    return result.reduce((prev: ModelLearningResult, current: ModelLearningResult) => {
      return (prev && prev.max_score > current.max_score) ? prev : current
    });
  }

  public navigateToReport(): void {
    switch ((this.dialogService.data as { data: string, mlCube: string }).mlCube) {
      case 'AUTO_ML_1':
        window.open(this.reportUrls.reportUrl1, '_blank');
        break;
      case 'AUTO_ML_2':
        window.open(this.reportUrls.reportUrl2, '_blank');
        break;
      case 'AUTO_ML_3':
        window.open(this.reportUrls.reportUrl3, '_blank');
        break;
      default:
        break;
    }
  }
}
