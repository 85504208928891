import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SafeHtmlPipe } from '@selfai-platform/shared';
import { Observable, switchMap, tap } from 'rxjs';
import { DiscoveryReportDomainService } from '../services';

@Component({
  selector: 'lib-discovery',
  imports: [AsyncPipe, SafeHtmlPipe],
  templateUrl: './discovery.component.html',
  styleUrl: './discovery.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscoveryComponent implements OnInit {
  protected parametrizationUrl: string;
  @ViewChild('reportContainer', { static: false }) reportContainer!: ElementRef;
  reportHtml$: Observable<string>;

  constructor(
    private readonly discoveryReportDomainService: DiscoveryReportDomainService,
    private readonly route: ActivatedRoute,
  ) {
    this.parametrizationUrl = this.discoveryReportDomainService.getParametrizationUrl();
  }

  ngOnInit(): void {
    this.reportHtml$ = this.route.params.pipe(
      switchMap((params) => {
        return this.discoveryReportDomainService.getReport(params['id']).pipe(
          tap(() => {
            setTimeout(() => {
              if (this.reportContainer) {
                this.executeScripts(this.reportContainer.nativeElement);
              }
            }, 300);
          }),
        );
      }),
    );
  }

  private executeScripts(element: HTMLElement) {
    const scripts = element.querySelectorAll('script');
    scripts.forEach((oldScript) => {
      const newScript = document.createElement('script');
      newScript.textContent = oldScript.textContent; // Copy JS-code
      oldScript.replaceWith(newScript); // Replace old <script> for new one
    });
  }
}
