import { HomePageWidgets } from '../home-page-widgets';
import { HomePageWidgetConfig } from './home-page-base-widget-config';

export const PipelineWidgetConfig: HomePageWidgetConfig = {
  name: HomePageWidgets.Pipeline,
  description: 'shell.homepage.widgets.pipeline.description',
  letter: 'shell.homepage.widgets.pipeline.letter',
  link: '/pipeline',
  linkTitle: 'shell.homepage.widgets.pipeline.linkTitle',
  title: 'shell.homepage.widgets.pipeline.title',
};

export const BiWidgetConfig: HomePageWidgetConfig = {
  name: HomePageWidgets.SelfaiBi,
  description: 'shell.homepage.widgets.kd.description',
  letter: 'shell.homepage.widgets.kd.letter',
  link: '/kd',
  linkTitle: 'shell.homepage.widgets.kd.linkTitle',
  title: 'shell.homepage.widgets.kd.title',
};

export const AggregatorsWidgetConfig: HomePageWidgetConfig = {
  name: HomePageWidgets.Aggregation,
  description: 'shell.homepage.widgets.aggregation.description',
  letter: 'shell.homepage.widgets.aggregation.letter',
  link: '/aggregators',
  linkTitle: 'shell.homepage.widgets.aggregation.linkTitle',
  title: 'shell.homepage.widgets.aggregation.title',
};


export const QuickPrototypesWidgetConfig: HomePageWidgetConfig = {
  name: HomePageWidgets.QuickPrototypes,
  description: 'shell.homepage.widgets.quick-prototypes.description',
  letter: 'shell.homepage.widgets.quick-prototypes.letter',
  link: '/pipeline/workflow/108f157c-2d56-4afe-8422-367e4813cfb3',
  linkTitle: 'shell.homepage.widgets.quick-prototypes.linkTitle',
  title: 'shell.homepage.widgets.quick-prototypes.title',
};

export const InterpretableModelsWidgetConfig: HomePageWidgetConfig = {
  name: HomePageWidgets.InterpretableModels,
  description: 'shell.homepage.widgets.interpretable-models.description',
  letter: 'shell.homepage.widgets.interpretable-models.letter',
  link: '/pipeline/workflow/8e082199-b462-4617-99e1-1c1109dab2b8',
  linkTitle: 'shell.homepage.widgets.interpretable-models.linkTitle',
  title: 'shell.homepage.widgets.interpretable-models.title',
};

export const HighPerfomanceModelsWidgetConfig: HomePageWidgetConfig = {
  name: HomePageWidgets.HighPerformanceModels,
  description: 'shell.homepage.widgets.high-perfomance-models.description',
  letter: 'shell.homepage.widgets.high-perfomance-models.letter',
  link: '/pipeline/workflow/361ebacb-7ae5-4554-b4f1-93ae7e3949c4',
  linkTitle: 'shell.homepage.widgets.high-perfomance-models.linkTitle',
  title: 'shell.homepage.widgets.high-perfomance-models.title',
};

export const LLMAgentsWidgetConfig: HomePageWidgetConfig = {
  name: HomePageWidgets.LLMAgents,
  description: 'shell.homepage.widgets.llm-agents.description',
  letter: 'shell.homepage.widgets.llm-agents.letter',
  link: '/pipeline/workflow/361ebacb-7ae5-4554-b4f1-93ae7e3949c4',
  linkTitle: 'shell.homepage.widgets.llm-agents.linkTitle',
  title: 'shell.homepage.widgets.llm-agents.title',
};

const allwidgets = {
  [HomePageWidgets.Pipeline]: PipelineWidgetConfig,
  [HomePageWidgets.SelfaiBi]: BiWidgetConfig,
  [HomePageWidgets.Aggregation]: AggregatorsWidgetConfig,
  [HomePageWidgets.QuickPrototypes]: QuickPrototypesWidgetConfig,
  [HomePageWidgets.InterpretableModels]: InterpretableModelsWidgetConfig,
  [HomePageWidgets.HighPerformanceModels]: HighPerfomanceModelsWidgetConfig,
  [HomePageWidgets.LLMAgents]: LLMAgentsWidgetConfig,
};

export function getStartPageWidgetsConfigs(widgetNames: HomePageWidgets[]) {
  return widgetNames.map((wName) => allwidgets[wName]);
}
